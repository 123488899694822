<template>
  <div class="reserve">
    <h3 class="title">Enter your {{ isFirstName ? "first" : "last" }} name.</h3>

    <div class="form-error" v-if="error.length > 0">
      <span>{{ error }}</span>
    </div>

    <form action="#" @submit="handleInput">
      <div class="input-control">
        <input
          autocomplete="off"
          placeholder="----"
          type="text"
          name="barcode"
          v-model="barcode"
          ref="barcodeInput"
          readonly="readonly"
          @input="onInputChange"
        />
        <button type="submit">{{ isFirstName ? "Next" : "Finish" }}</button>
      </div>
    </form>
    <SimpleKeyboard @onChange="onChange" :input="barcode" />

    <div class="modal-backdrop" v-if="modalShow">
      <div class="modal">
        {{ modalText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reserve {
  width: 85%;
}
.reserve .simple-keyboard {
  transform: scaleY(1.75) scaleX(1);
  transform-origin: top;
}

h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;
}

.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input {
  width: 90%;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
}

input:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background-color: #333;
  color: #fff;
  border: 1px solid #222;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 32px;
}
</style>

<script>
import SimpleKeyboard from "@/components/AlphaKeyboard";

export default {
  name: "Login",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      barcode: "",
      error: "",
      modalShow: false,
      modalText: "",
      polling: null,

      firstName: "",
      isFirstName: true,
    };
  },
  mounted() {
    if (this.$route.query.barcode) {
      if (!isNaN(this.$route.query.barcode)) {
        this.barcode = parseInt(this.$route.query.barcode).toString();
        this.handleInput(false);
      }
    }

    this.polling = setInterval(() => {
      this.focusInput();

      if (this.modalShow) {
        this.$refs.barcodeInput.disabled = true;
      } else {
        this.$refs.barcodeInput.disabled = false;
      }
    }, 0);
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  methods: {
    focusInput: function () {
      this.$refs.barcodeInput.focus();
    },
    handleInput: async function (e) {
      if (e) e.preventDefault();

      this.error = "";
      let barcode = this.barcode.trim();

      barcode = barcode.charAt(0).toUpperCase() + barcode.slice(1);
      if (barcode.length < 2) {
        this.error = "Please enter a valid name.";
        return;
      }

      if (this.isFirstName) {
        this.firstName = barcode;
        this.isFirstName = false;
        this.barcode = "";
        return;
      }

      const lastName = barcode;

      this.$router.push({
        name: "UserDashboard",
        query: {
          ...this.$route.query,
          firstName: this.firstName,
          lastName: lastName,
        },
      });

      this.barcode = "";

      return;
    },
    onChange(input) {
      this.barcode = input;
    },
    onInputChange(input) {
      this.barcode = input.target.value;
    },
  },
};
</script>
